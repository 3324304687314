<template>
	<div class="login h-100" id="wrap">
		<div class="language_btn">
			<!--언어선택-->
			<div class="language_btn01"> <span><img :src="`/assets/img/${langImg[langIndex]}`" class="language_img">{{ language }}<img src="/assets/img/bottom.png" class="bottom_img"></span>
				<div class="dep_01">
					<ul class="dep_02 lang_list">
						<li v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
							<a href="javascript:;" @click="SetLocale(i)">
								<img :src="`/assets/img/${langImg[i]}`" class="language_img">{{lang}}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div name='logfrm' method='POST'>
			<input type='hidden' name='lang' value="kr">
			<div class="index_l_box">
				<!--왼쪽 백그라운드 이미지-->
				<div class="inner" style="position:relative;">
					
					<div class="login_div">
						<div class="logo">
							<a href="javascript:void()" @click="GotoMove()">
								<img src="@/assets/logo.png" alt="mobilelogo" class="logo_color">
								<img src="@/assets/logo.png" alt="mobilelogo" class="logo_white">
							</a>
						</div>
						
						<div class="index_input id_input">
							<!-- <p class="input_title">{{$t("login.1")}}</p> -->
							<input type="text" class="index_input01" :placeholder="`${$t('login.2')}`" name="logid" id='userid' v-model="id" @keyup.enter='login()'>
						</div>
						<div class="index_input">
							<!-- <p class="input_title">{{$t("login.3")}}</p> -->
							<input type="password" class="index_input01" :placeholder="`${$t('login.4')}`"  name="logpwd" id='logpwd'  v-model="password" @keyup.enter='login()'>
						</div>
						<div class="index_input login_btn" @click="login()">
							<input type="button" class="button01" :value="`${$t('login.5')}`">
						</div>
						<div class="index_input flex-between-center">
							<div class="sign_btn" >
								<input type="button" class="text_btn small mt-0" :value="`${$t('login.6')}`" @click="$router.push({path:'/'+$i18n.locale+'/sign'})">
							</div>
							<div class="sign_btn" >
								<input type="button" class="text_btn small mt-0" :value="`${$t('login.11')}`" @click="$router.push({path:'/'+$i18n.locale+'/find-pw'})">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- / wrap end -->
</template>
<script>
const CryptoJS = require("crypto-js");
	export default {
		data(){
			return{
				lang:this.$i18n.locale,
				memo :'',
				title :'',
				summery :'',
				id : '',
				password:'',
				
				language : this.$t('language.1'),
				langs: [this.$t("language.1"), this.$t("language.2"), this.$t("language.3"), this.$t("language.4"), this.$t("language.5")],
				paramsLang: ['ko', 'en', 'jp', 'cn', 'it'],
				langImg: ['korea.png', 'usa.png', 'jpn.png', 'cha.png', 'Iitaly.png'],
				langIndex: 0  
			}
		},
		created(){
			
			this.SetLanguage();
		},
		mounted(){
			$('.sub-menu ul').hide();
			$(".sub-menu a").click(function () {
			$(this).parent(".sub-menu").children("ul").slideToggle("100");
			$(this).find(".right").toggleClass("fa-caret-up fa-caret-down");
			});
        },
		methods : {
			GotoMove(){
				this.$router.push({path : `/${this.$i18n.locale}/landing`})
			},
			SetLanguage(){
				var lang = this.$route.params.lang;
				if(lang == 'ko') {
					this.language =  this.$t("language.1");
					this.langIndex = 0;
				}else if(lang == 'en') {
					this.language = this.$t("language.2");
					this.langIndex = 1;
				}else if(lang == 'jp') {
					this.language = this.$t("language.3");
					this.langIndex = 2;
				}else if(lang == 'cn') {
					this.language = this.$t("language.4");
					this.langIndex = 3;
				}else if(lang == 'it') {
					this.language = this.$t("language.5");
					this.langIndex = 4;
				}else {
					this.language = this.$t("language.1");
					this.langIndex = 0;
				}
			},
			SetLocale: function(locale){
				this.$i18n.locale = this.paramsLang[locale];
            
				this.$router.push({
					params: {lang: this.paramsLang[locale]}
				})
				this.langs = [this.$t("language.1"), this.$t("language.2"), this.$t("language.3"), this.$t("language.4"), this.$t("language.5")];
				this.language = this.langs[locale];
				this.langIndex = locale;
				this.$router.go(0);
				
			},

			login : function(){
				
				const id =  this.id.toLowerCase();
				const password =  this.password;

				if(id ==''){
					this.$alert(this.$t('login.9'))
					return false;
				}

				if(password ==''){
					this.$alert(this.$t('login.10'))
					return false;
				}
				
				const body = {id,password};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/login/login`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								const token = body.token;
								const name = body.name;
								const id = body.id;
								
								const mem_info = {token,name,id};
								this.$store.dispatch('SETMEMBER',{mem_info}).then(
									()=>{
										window.location.href= '/'+this.$i18n.locale+'/landing';
										
									}
								);
							}else if(res.data.code=='100'){
								this.$alert(this.$t('login.7'))
							}else if(res.data.code=='110'){
								this.$alert(this.$t('login.8'))
							}else if(res.data.code=='120'){
								this.$alert(this.$t('login.7'))
							}
						}

					}
				).catch(() => {});
			}
		}
	}
</script>
<style>
</style>