<template>
	<div class="login h-100" id="wrap">
		<div class="language_btn">
			<!--언어선택-->
			<div class="language_btn01"> <span><img :src="`/assets/img/${langImg[langIndex]}`" class="language_img">{{ language }}<img src="/assets/img/bottom.png" class="bottom_img"></span>
				<div class="dep_01">
					<ul class="dep_02 lang_list">
						<li v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
							<a href="javascript:;" @click="SetLocale(i)">
								<img :src="`/assets/img/${langImg[i]}`" class="language_img">{{lang}}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div >
			<input type='hidden' name='lang' value="kr">
			<div class="index_l_box">
				<!--왼쪽 백그라운드 이미지-->
				<div class="inner" style="position:relative;">
					
					<div class="login_div">
						<div class="logo">
							<a href="#">
								<img src="@/assets/logo.png" alt="mobilelogo" class="logo_color">
								<img src="@/assets/logo.png" alt="mobilelogo" class="logo_white">
							</a>
						</div>

						<div v-if="step==1">
							<div class="index_input">
								<div class="mb-2 ps-1"><small>{{$t("login.12")}}</small></div>
								<input type="text" class="index_input01" :placeholder="`${$t('login.12')}`" name="logid" id='userid' v-model="id"  autocomplete='off' @keyup.enter='CheckId()'>
							</div>
							<div class="index_input login_btn" @click="CheckId()">
								<input type="button" class="button01" :value="`${$t('login.13')}`">
							</div>
						</div>
						<div v-if="step==2">
							<div class="index_input">
								<div class="mb-2 ps-1"><small>{{$t("login.15")}}</small></div>
								<input type="text" class="index_input01" :placeholder="`${$t('login.15')}`" name="logid" id='userid' v-model="code"  autocomplete='off' @keyup.enter='CheckAuthCode()'>
							</div>
							<div class="index_input login_btn" @click="CheckAuthCode()">
								<input type="button" class="button01" :value="`${$t('login.13')}`">
							</div>
						</div>
						<div v-if="step==3">
							<div class="index_input">
								<div class="mb-2 ps-1"><small>{{$t("login.17")}}</small></div>
								<input type="password" class="index_input01" :placeholder="`${$t('login.17')}`" name="logid" id='userid' v-model="password" @keyup.enter='ChangePassword()'>
							</div>
							<div class="index_input">
								<div class="mb-2 ps-1"><small>{{$t("login.18")}}</small></div>
								<input type="password" class="index_input01" :placeholder="`${$t('login.18')}`" name="logid" id='userid' v-model="password_conf" @keyup.enter='ChangePassword()'>
							</div>
							<div class="index_input login_btn" @click="ChangePassword()">
								<input type="button" class="button01" :value="`${$t('login.23')}`">
							</div>
						</div>
						<div class="index_input flex-between-center">
							<div class="sign_btn" >
								<input type="button" class="text_btn small mt-0" :value="`${$t('login.6')}`" @click="$router.push({path:'/'+$i18n.locale+'/sign'})">
							</div>
							<div class="sign_btn" >
								<input type="button" class="text_btn small mt-0" :value="`${$t('login.5')}`" @click="$router.push({path:'/'+$i18n.locale+'/login'})">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- / wrap end -->
</template>
<script>
const CryptoJS = require("crypto-js");
	export default {
		data(){
			return{
				password_conf : '',
				lang:this.$i18n.locale,
				memo :'',
				title :'',
				summery :'',
				id : '',
				password:'',
				step : '1',
				language : this.$t('language.1'),
				langs: [this.$t("language.1"), this.$t("language.2"), this.$t("language.3"), this.$t("language.4"), this.$t("language.5")],
				paramsLang: ['ko', 'en', 'jp', 'cn', 'it'],
				langImg: ['korea.png', 'usa.png', 'jpn.png', 'cha.png', 'Iitaly.png'],
				langIndex: 0  
			}
		},
		created(){
			
			this.SetLanguage();
		},
		mounted(){
			$('.sub-menu ul').hide();
			$(".sub-menu a").click(function () {
			$(this).parent(".sub-menu").children("ul").slideToggle("100");
			$(this).find(".right").toggleClass("fa-caret-up fa-caret-down");
			});
        },
		methods : {
			SetLanguage: function() {
				var lang = this.$route.params.lang;
				if(lang == 'ko') {
					this.language =  this.$t("language.1");
					this.langIndex = 0;
				}else if(lang == 'en') {
					this.language = this.$t("language.2");
					this.langIndex = 1;
				}else if(lang == 'jp') {
					this.language = this.$t("language.3");
					this.langIndex = 2;
				}else if(lang == 'cn') {
					this.language = this.$t("language.4");
					this.langIndex = 3;
				}else if(lang == 'it') {
					this.language = this.$t("language.5");
					this.langIndex = 4;
				}else {
					this.language = this.$t("language.1");
					this.langIndex = 0;
				}
			},
			SetLocale: function(locale){
				this.$i18n.locale = this.paramsLang[locale];
            
				this.$router.push({
					params: {lang: this.paramsLang[locale]}
				})
				this.langs = [this.$t("language.1"), this.$t("language.2"), this.$t("language.3"), this.$t("language.4"), this.$t("language.5")];
				this.language = this.langs[locale];
				this.langIndex = locale;
				this.$router.go(0);
				
			},
			CheckId(){
				
				const id =  this.id;
				const body = {id};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/login/CheckId`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								this.step = 2;
							}else if(res.data.code=='300'){
								this.step = 3;
							}else if(res.data.code=='100'){
								this.$alert(this.$t('login.14'))
							}
						}

					}
				).catch(() => {});
			},
			CheckAuthCode(){
				
				const code = this.code;
				const id =  this.id;
				const body = {id,code};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/login/CheckAuthCode`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								this.step = 3;
							}else if(res.data.code=='100'){
								this.$alert(this.$t('login.22'))
							}
						}

					}
				).catch(() => {});
			},
			ChangePassword(){
				const password = this.password;
				const password_conf = this.password_conf;

				
				const id =  this.id;
				
				if(password ==''){
					this.$alert(this.$t('login.19'))
					return false;
				}

				if(password != password_conf){
					this.$alert(this.$t('login.20'))
					return false;
				}

				const body = {id,password};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/login/ChangePassword`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								this.$alert(this.$t('login.24')).then(
									()=>{
										this.$router.push({path : `/${this.$i18n.locale}/login`})
									}
								)
							}else if(res.data.code=='100'){
								this.$alert(this.$t('login.22'))
							}
						}

					}
				).catch(() => {});
			}
		}
	}
</script>
<style>
</style>